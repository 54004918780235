<template>

<section class="hero">
	
		<div class="hero-logo-wrapper">
			<div class="hero-logo">
				<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 875.7 898.4" style="overflow:visible;enable-background:new 0 0 875.7 898.4;"
				xml:space="preserve" id="svg-logo">

				<defs>
				</defs>
				<g>
					<path id="letter-t" class="st0" d="M260.1,631.3c0,3.7-3,6.6-6.6,6.6h-74.6v252.3c0,3.7-3,6.6-6.6,6.6H90.1c-3.7,0-6.6-3-6.6-6.6V637.9H8.8
					c-3.7,0-6.6-3-6.6-6.6v-67.7c0-3.7,3-6.6,6.6-6.6h244.7c3.6,0,6.6,3,6.6,6.6V631.3z"/>
					<path id="letter-e" class="st0" d="M310.9,890.3V563.5c0-3.7,3-6.6,6.6-6.6h199.8c3.7,0,6.6,3,6.6,6.6v67.8c0,3.7-3,6.6-6.6,6.6h-111v48.3h95.9
					c3.7,0,6.6,3,6.6,6.6v67.8c0,3.7-3,6.6-6.6,6.6h-95.9v48.3h111c3.7,0,6.6,3,6.6,6.6v67.8c0,3.7-3,6.6-6.6,6.6H317.5
					C313.9,896.9,310.9,893.9,310.9,890.3z"/>
					<path id="letter-n" class="st0" d="M785.3,556.9h82.2c3.7,0,6.6,3,6.6,6.6v326.7c0,3.7-3,6.6-6.6,6.6h-84.9c-2.4,0-4.6-1.3-5.8-3.4L670.2,705.2
					v185.1c0,3.7-3,6.6-6.6,6.6h-82.2c-3.7,0-6.6-3-6.6-6.6V563.5c0-3.7,3-6.6,6.6-6.6h85.1c2.3,0,4.4,1.2,5.6,3.1l106.6,171.7V563.5
					C778.7,559.9,781.7,556.9,785.3,556.9z"/>
				</g>
				<g>
					<path id="letter-r" class="st1" d="M790.4,209.6c21.5-5.8,24.5-34.3,22-46.7c-2.1-10.7-12.3-20.1-27-27c-13.4-6.2-77.4-33.5-106-51
					c-28.6-17.5-90-80-90-80l-121,58c60.7,70.3,80.3,170.4,88,202c9.4,38.5-14,138-14,138l23,21l95-27c2.5-2.8,5.9-8.5,9.5-16.6
					c2.9-9.4,6.4-19.5,10.5-30.3c1.2-4.6,2.4-9.4,3.5-14.5c3.1-14.6-4.6-55.1-6.9-66.6c-0.3-1.7,0.8-3.3,2.4-3.6l43.9-8.4
					c-19,35.6-32.8,66.5-42.9,93.1c-3.3,12.1-7,22.4-10.5,30.3C646.1,456.7,657.5,488,657.5,488l25,26l109-52c-7.5-14.9-3-43-3-43
					c5-68.3,85-168,85-168l-4-23l-28-30c0,0-45.5,11.2-90.5,19.2c-24.9,4.4-54.8,9.4-79.5,13.8 M655.5,192c-0.1-11.9-30-67-30-67
					c10.4,8,51.8,32.9,75,40c23.2,7.1,5,19,5,19C686.9,192.9,655.5,192,655.5,192z"/>
					<path id="letter-o" class="st1" d="M548.5,320.1c0-0.1-0.1-0.1-0.1-0.2c-11.5-26.5-25.3-98.5-26-108.9c-3.7-38.8,18.3-139.8,22.4-158.1
					c0.4-1.8-0.1-3.7-1.3-5.1L503.7,2.4c-0.7-0.8-1.9-1.1-2.9-0.6l-127.8,58c-0.3,0.1-0.6,0.3-0.8,0.6C339,96.1,276.4,304.2,294.5,405
					c17.8,99.3,132,107.7,140.9,109c0.1,0,0.2,0,0.2,0c119.3-2.9,136.2-81.4,136.9-110.9c0-0.1,0-0.1,0-0.2
					C576.5,371.2,549.9,322.6,548.5,320.1z M429.5,419c-24-0.3-39-24.5-35-60.3c3.1-27.6,29.7-95.8,43-121.9c1.2-2.3,4.6-1.5,4.8,1.1
					c1.3,31.5,23,94.2,27.2,118.2C474.4,383.9,461.4,419.4,429.5,419z"/>
					<path id="letter-m" class="st1" d="M181.3,59L294.1,2.2c1.4-0.7,3.2-0.2,4,1.1c7.3,11.8,33.4,69.2-60.8,180.7c-0.5,0.6-0.8,1.4-0.7,2.2l3.6,48.3
					c0.1,1.8,1.9,3.1,3.7,2.8l0,0c1-0.2,1.8-0.9,2.2-1.8l32.8-77.1c0.4-0.9,1.1-1.5,2-1.8l118.8-32.1c1.1-0.3,2.3,0,3,0.9l16,16.8
					c1,1.1,1.1,2.7,0.3,3.9c-10.6,15.5-76.8,117.6-69.7,248.2c0.1,1.1-0.4,2.1-1.3,2.7l-90.6,61.7c-1.2,0.8-2.7,0.7-3.8-0.3L237.6,444
					c-0.7-0.6-1.1-1.6-1-2.5c1.2-16.3,12.7-159.9,37.9-214.5l-25.3,61.3c-0.4,1-1.4,1.8-2.5,1.9l-29.1,3.4c-1.7,0.2-3.2-1-3.4-2.7
					c-1.2-11.1-5.1-49.8-5.7-78.9c8.9-8-95.8,139.7-46.7,283.9c0.4,1.2,0,2.6-1,3.4l-20.7,16.4c-1,0.8-2.3,0.9-3.4,0.3L6.9,442.8
					c-0.9-0.5-1.6-1.4-1.8-2.5C2.5,429-9.8,362.7,33.4,288.1c0.1-0.1,0.1-0.2,0.2-0.3c2.7-3.7,65.5-91.4,94.9-118.9
					c30.1-28.1,43.2-49.9,46-66c1.3-7.6,5.3-31.6,5.1-41.1C179.6,60.7,180.2,59.6,181.3,59z"/>
					</g>
				</svg>
			</div>
		</div>
	<div class="hero-titles">
	
		<h1>Hællæ!</h1>
		<h2>I'm Morten, a digital designer who dabbles in front-end development.</h2>
		
	</div>
		
</section>

</template>

<script>

export default {
	name: "HeroComp"
};

</script>

<style>

/************************************************************************************** HERO */

.hero
{
	width: auto;
	height: calc(100vh - 125px);
	margin: 0 auto;
	padding: 0 10%;
	background: var(--dark-gray-color);
	display: grid;
	grid-template-columns: 40% 40%;
	grid-gap: 10%;
	align-items: center;
	justify-content: center;
	background: var(--light-gray-color);
	background-image: url(../assets/hero_bg.jpg);
	background-size: cover;
	background-position-y: 0;
	animation: hero-bg-anim 2s ease forwards;
	overflow: hidden;
}

@keyframes hero-bg-anim
{
	to{
		background-position-y: 200px;
	}
}

@media all and (max-width: 1300px)
{
	.hero
	{
		grid-gap: 100px;
	}
}

@media all and (max-width: 800px)
{
	.hero
	{
		/* min-height: calc(100vh - 160px); */
		/* height: 100vh; */
		height: auto;
		text-align: center;
		padding: 50px 0;
		grid-template-columns: 100%;
		grid-gap: 30px;
	}
}

/************************************************************************************** HERO LOGO */

.hero-logo-wrapper
{
	transform: translateX(-200px);
	opacity: 0;
	justify-self: end;
	animation: logo-anim 2s ease forwards;
}

@media all and (max-width: 800px)
{
	.hero-logo-wrapper{
		justify-self: center;
	}
}

@keyframes logo-anim
{
	to{
		opacity: 1;
		transform: translateX(0);
	}
}

.hero-logo
{
	width: 400px;
	height: 400px;
	background: var(--light-gray-color);
	background: #c2c2c2;
	border-radius: 50%;
	box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.4);
	display: grid;
	grid-template-columns: 1fr;
	align-items: center;
	
	position: relative;
	animation: circle-bg .5s ease forwards 1.5s;
}

@keyframes circle-bg {
	to{
		background: var(--light-gray-color);
	}
}

@media all and (max-width: 1300px)
{
	.hero-logo
	{
		width: 300px;
		height: 300px;
	}
}

@media all and (max-width: 800px)
{
	.hero-logo
	{
		width: 200px;
		height: 200px;
	}
}

#svg-logo {
        fill: transparent;
        animation: fill 1s ease forwards 1s, drop-shadow 1s ease forwards 2s;
        width: 200px;
        filter: none;
		stroke: #2c2c2c;
		stroke-width: 3;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-53%,-55%);
    }    

@media all and (max-width: 1300px)
{
	#svg-logo
	{
		width: 150px;
	}
}

@media all and (max-width: 800px)
{
	#svg-logo{
		width: 100px;
	}
}

#letter-m{
    stroke-dasharray: 2356;
	stroke-dashoffset: 2356;
	animation: line-anim 2.5s ease forwards;
}

#letter-o{
	stroke-dasharray: 1739;
	stroke-dashoffset: 1739;
	animation: line-anim 2.5s ease forwards ;
}

#letter-r{
	stroke-dasharray: 2254;
	stroke-dashoffset: 2254;
	animation: line-anim 2.5s ease forwards ;
}

#letter-t{
	stroke-dasharray: 1178;
	stroke-dashoffset: 1178;
	animation: line-anim 2.5s ease forwards;
}

#letter-e{
	stroke-dasharray: 1523;
	stroke-dashoffset: 1523;
	animation: line-anim 2.5s ease forwards ;
}

#letter-n{
	stroke-dasharray: 1835;
	stroke-dashoffset: 1835;
	animation: line-anim 2.5s ease forwards ;
}
    
@keyframes line-anim {
	to{
		stroke-dashoffset: 0;
	}
}

@keyframes fill {
	to{
		fill: #FFD942;
	}
}

@keyframes drop-shadow {
	to{
		filter: drop-shadow(5px 5px 0px var(--dark-gray-color));
	}
}

/************************************************************************************** HERO TITLES */

.hero-titles
{
	width: 500px;
	color: var(--text-color);
	transform: translateX(200px);
	opacity: 0;
	animation: title-anim 2s ease forwards ;
}

@keyframes title-anim {
	to{
		opacity: 1;
		transform: translateX(0);
	}
}

@media all and (max-width: 1300px)
{
	.hero-titles
	{
		width: 300px;
	}
}

@media all and (max-width: 800px)
{
	.hero-titles
	{
		width: 100%;
	}
}

.hero-titles h1
{
	color: var(--text-color);
	letter-spacing: -3px;
	font-size: 6rem;
	line-height: 6rem;
	
	font-weight: 700;
	margin: 0 0 25px 0;
}

.hero-titles h1::after
{
	display: none;
}

.hero-titles h2
{
	font-weight: 300;
	font-size: 2rem;
	line-height: 2.5rem;
	padding: 25px 0 0 0;
	border-top: 2px solid var(--callout-color);
	display: inline-block;
}

@media all and (max-width: 1300px)
{
	.hero-titles h1
	{
		font-size: 6rem;
		line-height: 6rem;
		margin-bottom: 30px;
	}
	
	.hero-titles h2
	{
		font-size: 2rem;
		line-height: 3rem;
	}
}

@media all and (max-width: 800px)
{
	.hero-titles h1
	{
		font-size: 3rem;
		line-height: 2.5rem;
		/* margin-bottom: 30px; */
		text-align: center;
		margin: 30px auto 20px auto;
		letter-spacing: 0;
	}
	
	.hero-titles h2
	{
		width: 80%;
		font-size: 1.5rem;
		line-height: 2rem;
		padding-top: 0;
		border-top: none;
	}
}
	
</style>